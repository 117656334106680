<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('sports.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="dataComputed.description"
              :label="t('sports.description')"
              outlined
              rows="2"
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>
        </v-row>

        <options-buttom-block
          :tab-number="tab"
          :is-mode-save-edit="option !== 2"
          :no-save="false"
          :info-required="false"
          :no-cancel="option === 3"
          :no-arrow="true"
          @onCancel="onCancelButton"
          @onNextTab="changeTab"
          @onSave="onSaveAction"
          @onDelete="(val) => $emit('delete', val)"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import { ref, computed, watch } from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomBlock from '@/views/components/options-buttom-block/OptionsButtomBlock.vue'

export default {
  components: {
    OptionsButtomBlock,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)
    const computedOption = computed(() => props.option)
    const computedOpen = computed(() => props.isOpen)

    const dataAcount = ref({})
    const initialData = {
      name: null,
      description: null,
      facility_id: null,
      status: 'A',
    }

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        description: null,
        facility_id: null,
        status: 'A',
      })
      emit('update-option', 1)
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    watch([computedOpen], async () => {
      if (!computedOpen.value) {
        await onCancelButton()
      }
    })

    return {
      // data
      form,
      dataAcount,
      initialData,

      // computed
      dataComputed,
      computedOption,
      computedOpen,
      statusOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,

      // validations
      required,
      emailValidator,
      integerValidator,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
