<template>
  <v-card
    id="contact-form"
    min-height="350"
    class="elevation-0"
  >
    <!-- actions -->
    <!-- <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition> -->

    <!-- tabs -->
    <!-- <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs> -->

    <!-- tabs item -->
    <!-- <v-tabs-items v-model="tab">
      <v-tab-item> -->
    <contact-tab-general
      v-if="isContact"
      :is-open="isOpen"
      :data-params="contactData"
      :tab="tab"
      :option="option"
      :is-user-app="isUserApp"
      :categories="categories"
      :search-user-error="searchedUserError"
      @updateSearchUserError="e => searchedUserError = e"
      @updateIsUserApp="e => isUserApp = e"
      @changeTab="changeTab"
      @save="saveAction"
      @open-drawer="(val) => $emit('open-drawer', val)"
      @update-option="(val) => $emit('update-option', val)"
      @delete="onDelete"
    ></contact-tab-general>

    <contact-category-tab-general
      v-if="!isContact"
      :is-open="isOpen"
      :data-params="contactCategoryData"
      :tab="tab"
      :option="option"
      :is-user-app="isUserApp"
      @updateIsUserApp="e => isUserApp = e"
      @changeTab="changeTab"
      @save="saveAction"
      @open-drawer="(val) => $emit('open-drawer', val)"
      @update-option="(val) => $emit('update-option', val)"
      @delete="onDelete"
    ></contact-category-tab-general>
    <!-- </v-tab-item>
    </v-tabs-items> -->
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import {
  getContactCategoryActiveFacility,
  createContact,
  editContact,
  createContactCategory,
  editContactCategory,
  getContact,
  getContactCategory,
  deleteContact,
  deleteContactCategory,
} from '@api'
import useSelectOptions from '@core/utils/useSelectOptions'
import Swal from 'sweetalert2'

// demos
import ContactTabGeneral from './ContactTabGeneral.vue'
import ContactCategoryTabGeneral from './ContactCategoryTabGeneral.vue'

export default {
  components: {
    ContactTabGeneral,
    ContactCategoryTabGeneral,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Number,
      required: false,
      default: 2,
    },
    isContact: {
      type: Boolean,
      required: false,
      default: true,
    },
    contactId: {
      type: Number,
      required: false,
      default: 0,
    },
    categoryId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const computedIsOpen = computed({
      get: () => props.isOpen,
      set: val => emit('update:is-open', val),
    })

    const tab = ref(0)

    // const isContact = ref(true)
    // const option = ref(1)
    // const contactId = ref(null)
    const isUserApp = ref(false)
    const pageData = ref()
    const filterData = ref()
    const contactData = ref({
      facility_id: null,
      user_app_id: null,
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      categories_ids: [],
    })
    const contactCategoryData = ref({
      name: null,
      description: null,
      facility_id: null,
      status: 'A',
    })
    const postData = ref({
      facility_id: null,
      user_app_id: null,
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      categories_ids: [],
    })
    const putData = ref({
      facility_id: null,
      user_app_id: null,
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      categories_ids: [],
    })
    const postDataCategory = ref({
      name: null,
      description: null,
      facility_id: null,
      status: 'A',
    })
    const putDataCategory = ref({
      name: null,
      description: null,
      facility_id: null,
      status: null,
    })
    const categories = ref([])
    const searchedUserError = ref(null)

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const goBack = () => {
      router.replace({
        name: 'views-contact-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const cancelAction = () => {
      tab.value = 0
      contactData.value = {
        facility_id: configFacility.value,
        user_app_id: null,
        fullname: null,
        phone_code: null,
        phone_prefix: null,
        phone_number: null,
        email: null,
        categories_ids: [],
      }
      contactCategoryData.value = {
        name: null,
        description: null,
        facility_id: null,
        status: 'A',
      }

      // tabs.value[1].disabled = true
      // tabs.value[2].disabled = true
    }

    const saveAction = async data => {
      // if (!option.value) {
      //   goBack()
      // }

      let body = {}
      let resp = null
      if (props.option === 1) {
        if (props.isContact) {
          body = {
            facility_id: configFacility.value,
            user_app_id: data.user_app_id,
            fullname: data.fullname,
            phone_code: data.phone_code,
            phone_prefix: data.phone_prefix,
            phone_number: data.phone_number,
            email: data.email,
            categories_ids: data.categories_ids,
          }
          resp = await createContact(body)
        } else {
          postDataCategory.value = Object.assign(postDataCategory.value, {
            name: data.name,
            description: data.description,
            facility_id: configFacility.value,
            status: data.status,
          })
          resp = await createContactCategory(postDataCategory.value)
        }
      }

      if (props.option === 3) {
        if (props.isContact) {
          body = {
            facility_id: configFacility.value,
            user_app_id: data.user_app_id,
            fullname: data.fullname,
            phone_code: data.phone_code,
            phone_prefix: data.phone_prefix,
            phone_number: data.phone_number,
            email: data.email,
            categories_ids: data.categories_ids,
          }
          resp = await editContact(body, data.id)
        } else {
          putDataCategory.value = Object.assign(putDataCategory.value, {
            name: data.name,
            description: data.description,
            facility_id: configFacility.value,
            status: data.status,
          })
          resp = await editContactCategory(putDataCategory.value, data.id)
        }
      }

      if (resp.ok) {
        // goBack()
        cancelAction()
        success(resp.message)
        emit('refresh')
      } else {
        // eslint-disable-next-line no-lonely-if
        if (props.isContact) {
          searchedUserError.value = resp.message.text
        } else {
          error(resp.message.text)
        }

        // if (resp.message.code === 'CON004') {
        //   contactData.value = {
        //     ...contactData.value,
        //     fullname: resp.data.fullname,
        //     email: resp.data.email,
        //     phone_number: resp.data.phone_number,
        //     user_app_id: resp.data.user_app_id,
        //   }
        //   isUserApp.value = true
        // }
      }
    }

    const onDelete = async () => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          let response = null
          if (props.isContact) response = await deleteContact(props.contactId)
          else response = await deleteContactCategory(props.categoryId)
          if (response && response.ok) {
            success(response.message)
            emit('refresh')

            // if (props.isContact) await fetchContacts()
            // else await fetchContactCategories()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    const initContactForm = async () => {
      // option.value = route.value.params.option || 2
      // contactId.value = route.value.params.id
      // isContact.value = route.value.params.isContact ? route.value.params.isContact === 'contact' : false
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      if (props.contactId || props.categoryId) {
        let resp = null
        if (props.isContact) resp = await getContact(props.contactId)
        else resp = await getContactCategory(props.categoryId)

        if (resp.ok) {
          if (props.isContact) {
            contactData.value = resp.data
            isUserApp.value = !!resp.data.user_app_id
          } else contactCategoryData.value = resp.data
        }
      }

      if (props.isContact) {
        const responseCategories = await getContactCategoryActiveFacility(configFacility.value || 0)
        categories.value = responseCategories.data
      }
    }

    watch([configOrganization, configFacility], () => {
      emit('refresh')
    })

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        await initContactForm()
      }
    })

    onMounted(async () => {
      await initContactForm()
    })

    return {
      tab,
      tabs,

      // isContact,
      isUserApp,

      // option,
      // contactId,
      contactData,
      contactCategoryData,
      postData,
      putData,
      postDataCategory,
      putDataCategory,
      categories,
      pageData,
      filterData,
      searchedUserError,

      configOrganization,
      configFacility,

      changeTab,
      saveAction,
      cancelAction,
      onDelete,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
